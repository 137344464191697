import type { RouteLocationNormalized, Router } from "vue-router";
import { msalInstance, loginRequest } from "../includes/azureConfig";
import type { PopupRequest, RedirectRequest } from "@azure/msal-browser";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";

export function registerGuard(router: Router) {
  router.beforeEach(async (to: RouteLocationNormalized) => {
    const requiresAuth = to.meta.requiresAuth || false;
    if (requiresAuth) {
      const request = {
        ...loginRequest,
        redirectStartPage: to.fullPath,
      };
      const shouldProceed = await isAuthenticated(
        msalInstance,
        InteractionType.Redirect,
        request
      );
      if (!shouldProceed && to.name === "overview") {
        return msalInstance.loginRedirect(); // Redirect to the login page if the user is not authenticated
      }
      return shouldProceed || "/failed";
    }
    return true;
  });
}

export async function isAuthenticated(
  instance: PublicClientApplication,
  interactionType: InteractionType,
  loginRequest: PopupRequest | RedirectRequest
): Promise<boolean> {
  try {
    await instance.handleRedirectPromise();

    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      return true;
    }

    if (interactionType === InteractionType.Popup) {
      await instance.loginPopup(loginRequest);
      return true;
    } else if (interactionType === InteractionType.Redirect) {
      await instance.loginRedirect(loginRequest);
      return true;
    }

    return false;
  } catch (error) {
    console.error("Authentication error:", error);
    return false;
  }
}
